.notification-listing {
  display: flex;
  flex-direction: column;
  align-items: center;

  .notification-card {
    width: 100%;
    margin: 10px;
    padding: 15px;
    background: linear-gradient(to bottom right, #4e4e4e, #3a3a3a);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    .notification-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      margin-bottom: 10px;

      svg {
        margin-right: 5px;
      }
    }

    .notification-body {
      p {
        margin-bottom: 10px;
        font-size: 16px; /* Adjust font size as needed */
        font-weight: bold; /* Set font weight to bold */
        color: #61dafb; /* Change text color to match the background */
      }

      small {
        font-size: 14px; /* Adjust font size as needed */
        opacity: 0.7;
        color: #ccc; /* Adjust text color as needed */
      }
    }
  }
}

.pagination {
  display: flex;
  margin-top: 20px;

  span {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;

    &.active {
      background-color: #61dafb;
      color: white;
      border-radius: 5px;
    }
  }
}
