.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
    Appearance: none;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4A93FF;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4a92ffbe;
}