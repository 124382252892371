/* Add this to your CSS for styling */
.inventory-tabs-list .nav-link.active-tab {
    border-bottom: 2px solid #007bff; /* Highlight color for the selected tab text */
    color: #054991; /* Text color for the selected tab text */
  }
  
  .dummy-tab-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .left-section-container {
    height: calc(100vh - 380px);
    overflow-y: auto;
  }
/* Hide scrollbar for Chrome, Safari, and Opera */
.left-section-container::-webkit-scrollbar {
  width: 0.5em;
}

/* Track */
.left-section-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

/* Handle */
.left-section-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Handle on hover */
.left-section-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.category-text {
  font-size: 14px;
  color: #333; /* Choose an appropriate color */
  margin-top: 8px; /* Adjust spacing as needed */
}


