.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.tooltip-text {
  display: none;
  background-color: rgb(15, 12, 12);
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.tooltip-btn {
  cursor: pointer;
}

.tooltip-btn:hover .tooltip-text {
  display: block;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.zindex-top {
  z-index: 999;
}

.nav-pills .nav-link.active {
  background-color: #e6edff;
  color: black;
}

.w-fit {
  width: fit-content;
}

.w-60 {
  width: 55%;
}

.w-40 {
  width: 45%;
}

.invalid-image {
  width: 60px;
  height: 60px;
}

.invalid-text {
  font-size: 16px;
  font-weight: 500;
}

.product-catelog-warning-box {
  background-color: #fefce8;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 1.05rem;
  color: black;
  font-weight: 500;
}

.product-uplaod-main-image {
  width: 200px;
  height: 200px;
}

.catalog-images {
  width: 20%;
  aspect-ratio: 1;
}

.product-main-image {
  width: 100px;
  aspect-ratio: 1;
}

.cross-catalog-image {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-display-select-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(33% - 20px);
  margin: 10px;
  min-width: 350px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}
.product-display-main-image {
  width: 100%;
  max-width: 300px;
  aspect-ratio: 1;
}

.youtube-thumbnail {
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.container {
  max-width: 1600px !important;
}

.profile-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #007bff; 
  color: #fff; 
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
}
