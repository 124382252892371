.sidebar {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

.sidebar.open {
  max-height: 1000px;
}


.back-btn {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: #0056b3;
}

.back-icon {
  margin-right: 8px;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
  .back-btn {
    font-size: 8px; /* Font size for mobile screens */
  }
  .font-weight-mobile{
    font-size: 14px; /* Font size for mobile screens */

  }
}
@media screen and (max-width: 768px) {
  .mx-xs-1 {
    margin-left: 4px !important; /* Adjust as needed */
    margin-right: 4px !important; /* Adjust as needed */
  }
}
