.rightsidrbarnew {
    background: #efefef;
    border-radius: 20px;
    width: 100%;
    min-height: 200px;
    padding: 10px 20px;
    margin-bottom: 30px;
  
    & div {
      display: flex;
      align-items: start;
      justify-content: start;
      flex-direction: column;
  
      & img {
        height: 100px;
      }
  
      & p {
        color: #7C8DB5;
      }
    }
  
    @media (min-width: 1200px) {
      & div {
        flex-direction: row;
      }
    }
  }
  