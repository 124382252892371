// 
// profile.scss
//

@mixin mobile() {
    @media screen and (max-width:640px) {
        @content
    }
}

;

@mixin tablet() {
    @media screen and (max-width:1024px) {
        @content
    }
}

;

@mixin smalllap() {
    @media screen and (max-width:1310px) {
        @content
    }
}

.myprofile {
    display: grid;
    grid-template-columns: 3fr 1fr;

    @include tablet {
        grid-template-columns: auto;
        // gap: 20px;
    }
}


.profilebtns {
    @include tablet {
        grid-template-columns: auto auto auto;
        width: 100vw;
        padding: 10px;
        height: initial;
    }

    @include mobile {
        row-gap: 20px;
        column-gap: 10px;
        grid-template-columns: auto auto auto;
        padding: 10px;

    }

    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    display: grid;
    grid-template-columns: auto auto auto auto;
    height: 400px;
    row-gap: 30px;
    column-gap: 30px;
    padding: 30px 30px 30px 0;

    // overflow: hidden;
}

.profileright {
    overflow: hidden;

    @include mobile {
        //         margin: 20px;
        display: none;
        // background: #000;
    }
}

.box {

    @include mobile {
        padding: 10px 0;
        height: 80px;

    }

    @include tablet {
        padding: 10px 0;

    }

    width: 100%;
    height: 150px;
    padding: 30px 30px;

    // margin: 0 20px 20px 20px;
    background: #fff;
    border: 1px solid #697475;
    border-radius: 12px;
    // height: 100%;
    cursor: pointer;
    display: grid;
    place-items: center;
    box-shadow: 2px 2px 2px lightgray;


    >div {
        font-weight: 600;
        font-size: 25px;
        color: #3e3e3e;
        user-select: none;

        @include mobile {
            font-size: 15px;
        }

        >div {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;

            @include mobile {
                font-size: 8px;
            }
        }
    }



    &:hover {
        background: #f8f8f8;
        box-shadow: none;
    }

    &:active {
        // background: #e6e6e6;
        box-shadow: 2px 2px 5px rgb(170, 170, 170);
    }
}

.rightaccount {
    @include mobile {
        padding: 0 20px;
    }
}

.accountsetting {
    @include mobile {
        width: 75%;
        font-size: 12px;
    }
}