.my-form-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .submit-button {
    background-color: #007bff;
    border-color: #007bff;
    &:hover {
      background-color: #0056b3;
      border-color: #0056b3;
    }
  }
  